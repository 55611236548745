<template>
  <form @submit.prevent class="w-100 h-100">
    <div class="row mx-0 h-100 justify-content-center">
      <div class="col-12 px-0 bg_overlay">
        <div class="row m-0 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-9 text-center p-0 mb-3 card_heading" v-if="page === 0">
                Be one of thousands enabling us to make a difference to the world!
              </div>
              <div class="col-12 p-0 mt-3 mb-5">
                <div class="row justify-content-center">
                  <div class="col-auto p-0 relative text-center">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider opacity">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 0}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              1
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        Your Details
                      </div>
                    </div>
                  </div>
                  <div class="col-auto p-0 relative text-center">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 1}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              2
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        Address
                      </div>
                    </div>
                  </div>
                  <div class="col-auto p-0 relative text-center">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 2}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              3
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider" :class="{opacity: !isUK}">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        Contact
                      </div>
                    </div>
                  </div>
                  <div class="col-auto p-0 relative text-center" v-if="isUK">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 3}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              4
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider opacity">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        GiftAid
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-10 p-0">
                <div class="row m-0" v-if="page === 0">
                  <div class="col-12 mb-3">
                    <SelectAll
                      v-model="v$.userTitleId.$model"
                      id="title"
                      :options="titles"
                      placeholder="Title"
                      displayValue="userTitleDescription"
                      saveValue="userTitleID"
                      color="gold"
                      :errors="v$.userTitleId.$errors"
                      :hasErrors="v$.userTitleId.$errors.length"
                      :isSearch="true"
                      width="full"
                    />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="First Name:" placeholder="First Name" v-model="firstName" id="firstName" color="gold" :errors="v$.firstName.$errors" :hasErrors="v$.firstName.$errors.length" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Last Name:" placeholder="Last Name" v-model="lastName" id="lastName" color="gold" :errors="v$.lastName.$errors" :hasErrors="v$.lastName.$errors.length" />
                  </div>
                  <!-- <div class="col-md-12 mb-3 px-1" v-if="!isUK">
                    <TextField type="text" label="Tax Number:" placeholder="Tax Number" v-model="individualTaxNumber" id="individualTaxNumber" color="gold" />
                  </div> -->
                  <div class="col-12 mb-3">
                    <TextField type="email" label="Email Address:" placeholder="email@mail.com" v-model="username" id="username" color="gold" autocomplete="username" :errors="v$.username.$errors" :hasErrors="((v$.username.$errors &&  v$.username.$errors.length > 0) || emailExists) ? 1 : 0" @change="checkEmail" />
                  </div>
                   <div class="col-12 mb-3" v-if="emailExists">
                    <div class="row mx-0">
                      <div class="col-6 red-text p-0 bold">
                        Email already registered
                      </div>
                      <div class="col-6 p-0">
                        <Button color="gold" btnText="Find your Donor Details" size="xsmall" icon="arrow" class="w-100" @buttonClicked="findDetails">
                          <IconArrowForward color="black" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-4">
                    <TextField type="password" label="Password:" placeholder="Password" v-model="password" id="password" color="gold" autocomplete="new-password" :errors="v$.password.$errors" :hasErrors="v$.password.$errors.length" />
                  </div>
                  <div class="col-6 mb-4">
                    <TextField type="password" label="Confirm Password:" placeholder="Confirm Password" v-model="confirmPassword" id="confirmPassword" color="gold" autocomplete="confirm-password" :errors="confirmErrorText" :hasErrors="confirmErrors" @input="checkPasswordsMatch" />
                  </div>
                </div>
                <div class="row m-0" v-if="page === 1">
                  <div class="col-12 mb-3">
                    <MapAutocomplete
                      @inputChanged="updateAddress"
                      placeholder="Search Address"
                      id="address"
                      :value="primaryAddress"
                      :map="true"
                      color="outline-green"
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <TextField type="text" label="Address Line 1:" placeholder="Address Line 1" v-model="primaryAddress.addressLine1" id="addressLine1" color="gold" :errors="v$.primaryAddress.addressLine1.$errors" :hasErrors="v$.primaryAddress.addressLine1.$errors.length" />
                  </div>
                  <div class="col-12 mb-3">
                    <TextField type="text" label="Address Line 2:" placeholder="Address Line 2" v-model="primaryAddress.addressLine2" id="addressLine2" color="gold" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Town/City:" placeholder="Town/City" v-model="primaryAddress.town" id="town" color="gold" :errors="v$.primaryAddress.town.$errors" :hasErrors="v$.primaryAddress.town.$errors.length" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Province/Country:" placeholder="Province" v-model="primaryAddress.province" id="province" color="gold" v-if="isUK" />
                    <TextField type="text" label="Province:" placeholder="Province" v-model="primaryAddress.province" id="province" color="gold" v-else />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Postal Code:" placeholder="Postal Code" v-model="primaryAddress.postalCode" id="postalCode" color="gold" />
                  </div>
                  <div class="col-6 mb-3">
                    <SelectCountry
                      v-model="primaryAddress.country"
                      id="country"
                      :options="countries"
                      placeholder="Country"
                      displayValue="description"
                      color="gold"
                      label="Country"
                    />
                    <!-- <TextField type="text" label="Country:" placeholder="Country" v-model="primaryAddress.country.description" id="country" color="gold" /> -->
                  </div>
                </div>
                <div class="row m-0" v-if="page === 2">
                  <div class="col-12" v-if="!isUK">
                    <div class="row">
                      <TabsComponent :tabOptions="tabOptions" v-model="selectedResidency" />
                    </div>
                    <div class="row" v-if="selectedResidency === 'non-sa resident'">
                      <div class="col-6 mb-3">
                        <TextField type="text" label="Passport No.:" placeholder="Passport No." v-model="passportNumber" id="passportNumber" color="gold" />
                      </div>
                      <div class="col-6 mb-3">
                        <SelectCountry
                          v-model="passportCountryOfIssue"
                          id="passportCountryOfIssue"
                          :options="countries"
                          placeholder="Country of Issue"
                          displayValue="description"
                          color="gold"
                          label="Country of Issue"
                          :errors="passportCountryError"
                          :hasErrors="passportCountryError.length"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 mb-3" v-if="selectedResidency === 'sa resident'">
                        <TextField type="text" label="SA ID Number:" placeholder="ID Number" v-model="idNumber" id="idNumber" color="gold" :errors="!idError ? [] : ['Error']" :hasErrors="!idError ? 0 : 1" />
                      </div>
                      <div class="col-6 mb-3">
                        <TextField type="text" label="Income Tax Number:" placeholder="Income Tax Number" v-model="individualTaxNumber" id="individualTaxNumber" color="gold" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-4">
                    <PhoneFieldInt type="tel" label="Mobile No.:" :showError="true" v-model:modelValue="cell" v-model:theError="isValidNumber" id="cell" color="gold" :errors="v$.cell.$errors" :hasErrors="v$.cell.$errors.length" ></PhoneFieldInt>
                  </div>
                  <div class="col-6 mb-4">
                    <PhoneFieldInt type="tel" label="Telephone No.:" v-model:modelValue="telHome" v-model:theError="isValidOther" id="tel" color="gold" ></PhoneFieldInt>
                  </div>
                  <div class="col-6 mb-3 consent">
                    <div class="mb-3">I consent to receiving transactional contact from Al-Imdaad Foundation via:</div>
                    <div class="form-check" v-for="item in correspondenceOptions" :key="item.id">
                      <input class="form-check-input" type="checkbox" :value="item" :id="item.correspondenceID" v-model="correspondences">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{item.description}}
                      </label>
                    </div>
                  </div>
                  <div class="col-6 mb-3 consent">
                    <div class="mb-3">I consent to receiving updates from Al-Imdaad Foundation via:</div>
                    <div class="form-check" v-for="item in marketingCorrespondenceOptions" :key="item.id">
                      <input class="form-check-input" type="checkbox" :value="item" :id="item.correspondenceID" v-model="correspondences">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{item.description.slice(9)}}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row m-0" v-if="page === 3">
                  <div class="col-12 mb-3">
                    <div class="row m-0 justify-content-center">
                      <div class="col-6 mb-3">
                        <img src="../../../assets/images/Gift-Aid-Logo-Green.png" class="img-fluid" />
                      </div>
                    </div>
                    <div class="row m-0 justify-content-center">
                      <div class="col-9 mb-3 giftAid_text">
                        Increase your donation by 25% at no extra cost!
                      </div>
                    </div>
                    <div class="row m-0 justify-content-center">
                      <div class="col-9 text-center red-text" :class="{'opacity': giftAid === true || giftAid === false}">
                        Please select one
                      </div>
                    </div>
                    <div class="row m-0 justify-content-center">
                      <div class="col-auto mb-3 consent">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :value="true" id="giftAidYes" name="giftAid" v-model="giftAid">
                          <label class="form-check-label" for="giftAidYes">
                            Yes
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :value="false" id="giftAidNo" name="giftAid" v-model="giftAid">
                          <label class="form-check-label" for="giftAidNo">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 mb-3 consent">
                        I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years, to Al-Imdaad Foundation UK. I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to pay any difference. Al-Imdaad Foundation UK will claim 25p on every £1 donated.
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 text-center medium">
                        <Button color="transparent" btnText=" Learn more about Gift Aid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-0" v-if="page === null">
                  <div class="col-12 mb-3">
                    There is already an account associated with that email address.<br/>
                    Try logging in. An email has been sent to {{username}} to reset your password if you can't remember it.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 align-self-end">
        <div class="row m-0 background-green py-3 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center" v-if="page === null">
              <div class="col-auto">
                <Button color="green_light" width="100" btnText="Login" icon="arrow" @buttonClicked="login">
                  <IconArrowForward color="white" />
                </Button>
              </div>
            </div>
            <div class="row m-0 justify-content-center" v-else>
              <div class="col-auto" v-if="page !== 0">
                <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="back">
                  <template v-slot:iconStart>
                    <IconArrowBack color="white" />
                  </template>
                </Button>
              </div>
              <div class="col-auto" v-if="isUK">
                <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="findDetails" v-if="emailExists">
                  <IconArrowForward color="white" />
                </Button>
                <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="next" v-else-if="page !== 3">
                  <IconArrowForward color="white" />
                </Button>
                <div  v-else-if="page === 3">
                  <Button color="green_light" width="100" btnText="Register now" icon="arrow" disabled v-if="giftAid !== true && giftAid !== false">
                    <IconArrowForward color="white" />
                  </Button>
                  <Button color="green_light" width="100" btnText="Register now" icon="arrow" @buttonClicked="signup" v-else :isLoading="userStatus === 'logging in' || userStatus === 'loading'">
                    <IconArrowForward color="white" />
                  </Button>
                </div>
              </div>
              <div class="col-auto" v-else>
                <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="findDetails" v-if="emailExists">
                  <IconArrowForward color="white" />
                </Button>
                <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="next" v-else-if="page !== 2">
                  <IconArrowForward color="white" />
                </Button>
                <Button color="green_light" width="100" btnText="Register now" icon="arrow" @buttonClicked="signup" v-else-if="page === 2" :isLoading="userStatus === 'logging in' || userStatus === 'loading'">
                  <IconArrowForward color="white"/>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup v-if="showPopup" @close="showPopup = false">
        <div class="row justify-content-center">
          <div class="col-4">
            <ExclamationAnimation />
          </div>
          <div class="col-11 text-center biggest_font bold green-text mb-3">
            A donor profile with this email is already registered
          </div>
          <div class="col-11 text-center mb-3">
            <Button color="green_light" width="100" btnText="Send my Login Details via Email" icon="arrow" @buttonClicked="sendDetails" :isLoading="isLoading">
              <IconArrowForward color="white" />
            </Button>
          </div>
        </div>
      </Popup>
      <Popup v-if="isSent" @close="isSent = false">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
            <TickAnimation v-else />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Login Details Sent
          </div>
          <div class="col-10 mb-3 bigger_font text-center medium green-text">
            Your login details have been sent to your registered email.
          </div>
          <div class="col-10 mb-3 text-center font15">
            Note: Please check your spam/junk folder to avoid missing our response.
          </div>
        </div>
      </Popup>
    </div>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { Capacitor } from '@capacitor/core'
import { SavePassword } from 'capacitor-ios-autofill-save-password'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    MapAutocomplete: defineAsyncComponent(() => import('@/components/MapAutocomplete.vue')),
    SelectCountry: defineAsyncComponent(() => import('@/components/SelectProject.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    ExclamationAnimation: defineAsyncComponent(() => import('@/components/animations/ExclamationAnimation.vue')),
    PhoneFieldInt: defineAsyncComponent(() => import('@/components/PhoneFieldInt.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue')),
    TabsComponent: defineAsyncComponent(() => import('@/components/TabsComponent.vue')),
    Popup: defineAsyncComponent(() => import('../../Popup.vue'))
  },
  props: ['isRedirect'],
  name: 'Sign Up',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      userTitleId: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      password: {
        required,
        minLength: minLength(6)
      },
      // confirmPassword: {
      //   required,
      //   sameAs: sameAs('password')
      // },
      username: {
        required,
        email
      },
      cell: {
        required
      },
      primaryAddress: {
        addressLine1: {
          required
        },
        town: {
          required
        }
      }
    }
  },
  data () {
    return {
      tabOptions: ['sa resident', 'non-sa resident'],
      selectedResidency: 'sa resident',
      isValidNumber: true,
      isValidOther: true,
      page: 0,
      userTitleId: '',
      firstName: '',
      lastName: '',
      idNumber: '',
      passportNumber: '',
      passportCountryOfIssue: null,
      idError: false,
      individualTaxNumber: '',
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      cell: null,
      telHome: null,
      primaryAddress: {
        addressLine1: '',
        addressLine2: '',
        town: '',
        postalCode: '',
        country: {
          countryID: null,
          description: '',
          isoCountryCode2: ''
        },
        province: ''
      },
      giftAid: null,
      consent: true,
      correspondences: [],
      confirmErrors: 0,
      confirmErrorText: [],
      zakaatCalculationDate: '',
      emailExists: false,
      showPopup: false,
      isLoading: false,
      isSent: false
    }
  },
  computed: {
    ...mapGetters([
      'titles',
      'countries',
      'correspondenceOptions',
      'marketingCorrespondenceOptions',
      'userStatus',
      'isApp'
    ]),
    isValid () {
      if (this.cell && !this.isValidNumber) {
        return false
      } else {
        return true
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    passportCountryError () {
      if (this.selectedResidency === 'non-sa resident' && this.passportNumber && this.passportNumber !== '') {
        if (!this.passportCountryOfIssue) {
          return ['Required if using a passport number']
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchTitles',
      'fetchCorrespondence',
      'fetchMarketingCorrespondence',
      'registerDonor',
      'findUser',
      'checkUserExists',
      'verifyIDNumber'
    ]),
    async checkEmail () {
      const ret = await this.checkUserExists(this.username)
      if (ret) {
        if (ret === true) {
          this.emailExists = true
        } else {
          this.emailExists = false
        }
      } else {
        this.emailExists = false
      }
    },
    findDetails () {
      this.showPopup = true
    },
    async sendDetails () {
      this.isLoading = true
      const searchDetails = {
        email: this.username
      }
      const ret = await this.findUser(searchDetails)
      if (ret) {
        this.showPopup = false
        this.isLoading = false
        this.isSent = true
      }
    },
    checkPasswordsMatch () {
      if (this.password !== this.confirmPassword) {
        this.confirmErrors = 1
        this.confirmErrorText = ['Passwords do not match']
      } else {
        this.confirmErrors = 0
        this.confirmErrorText = []
      }
    },
    async next () {
      if (this.password !== this.confirmPassword) {
        this.confirmErrors = 1
        this.confirmErrorText = ['Passwords do not match']
        return
      }
      this.v$.$touch()
      switch (this.page) {
        case 0:
          if (this.v$.userTitleId.$invalid || this.v$.firstName.$invalid || this.v$.lastName.$invalid || this.v$.username.$invalid || this.v$.password.$invalid) {
            break
          }
          this.page = this.page + 1
          return true
        case 1:
          if (this.v$.primaryAddress.addressLine1.$invalid || this.v$.primaryAddress.town.$invalid) {
            return false
          }
          if (this.isUK) {
            this.correspondences = []
          } else {
            this.correspondences = this.correspondenceOptions
          }
          this.page = this.page + 1
          return true
        case 2:
          if (this.v$.cell.$invalid || !this.isValidNumber) {
            return false
          }
          this.page = this.page + 1
          return true
      }
    },
    back () {
      this.page = this.page - 1
    },
    updateAddress (val) {
      const country = this.countries.find(item => item.description === val.country)
      var theTown = ''
      if (val.locality) {
        theTown = val.locality
      } else if (val.postal_town) {
        theTown = val.postal_town
      }
      const address = {
        addressLine1: `${val.street_number} ${val.route}`,
        addressLine2: val.sublocality_level_1,
        town: theTown,
        postalCode: val.postal_code,
        country: country,
        province: val.province
      }
      this.primaryAddress = address
    },
    login () {
      this.$emit('login')
    },
    async signup () {
      const isFormCorrect = await this.v$.$validate()
      const searchResults = await this.search()
      const country = this.countries.find(item => item.description === this.primaryAddress.country.description)
      this.primaryAddress.country = country
      if (!isFormCorrect) return
      if (searchResults.donorStatus !== 'MATCHED_EMAIL_SENT') {
        let idRet = true
        if (!this.isUK && this.idNumber && this.selectedResidency === 'sa resident') {
          idRet = await this.verifyID()
        }
        if (idRet) {
          let user = {
            userTitleId: this.userTitleId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.username,
            username: this.username,
            password: this.password,
            confirmPassword: this.confirmPassword,
            cell: this.cell,
            telHome: this.telHome,
            primaryAddress: this.primaryAddress,
            zakaatCalculationDate: this.zakaatCalculationDate,
            correspondence: this.correspondences,
            giftAid: this.giftAid,
            idNumber: this.idNumber,
            individualTaxNumber: this.individualTaxNumber
          }
          if (!this.isUK) {
            user = {
              userTitleId: this.userTitleId,
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.username,
              username: this.username,
              password: this.password,
              confirmPassword: this.confirmPassword,
              cell: this.cell,
              telHome: this.telHome,
              primaryAddress: this.primaryAddress,
              zakaatCalculationDate: this.zakaatCalculationDate,
              correspondence: this.correspondences,
              giftAid: this.giftAid,
              idNumber: this.idNumber,
              individualTaxNumber: this.individualTaxNumber,
              passportNumber: this.passportNumber,
              countryOfIssueID: 1
            }
            if (this.selectedResidency === 'non-sa resident') {
              user.idNumber = ''
              if (this.passportCountryOfIssue && this.passportCountryOfIssue.countryID) {
                user.countryOfIssueID = this.passportCountryOfIssue.countryID
              }
            } else {
              user.passportNumber = ''
              user.countryOfIssueID = 1
            }
          }
          const resp = await this.registerDonor(user)
          if (resp && resp === 1 && this.userStatus === 'logged in') {
            if (Capacitor.getPlatform() === 'ios') {
              await SavePassword.promptDialog({
                username: '[the username that was entered]',
                password: '[the password that was entered]'
              })
            }
            if (this.isRedirect) {
              this.$emit('close')
              this.$router.push('/profile/0')
            } else {
              this.$emit('loggedIn')
            }
          }
        }
      } else {
        this.page = null
      }
      // actually submit form
    },
    async search () {
      const searchDetails = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.username,
        postalAddress1: this.primaryAddress.addressLine1,
        town: this.primaryAddress.town,
        countryName: this.primaryAddress.country.description,
        postalcode: this.primaryAddress.postalCode,
        province: this.primaryAddress.province
      }
      return this.findUser(searchDetails)
    },
    async verifyID () {
      const ret = await this.verifyIDNumber(this.idNumber)
      if (ret === true) {
        this.idError = false
      } else {
        this.idError = true
      }
      return ret
    }
  },
  async mounted () {
    await this.fetchTitles()
    await this.fetchCorrespondence()
    await this.fetchMarketingCorrespondence()
  }
}
</script>

<style scoped>
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.2rem;
  color: var(--green-color);
}
.giftAid_text {
  font-family: "quicksand", Sans-serif;
  font-size: 1.7rem;
  color: var(--green-color-dark);
  line-height: 1.5;
  text-align: center;
}
.background-green {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  color: #fff;
  font-weight: bold;
}
.cursor:hover {
  text-decoration: underline;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.wizard_step {
  border-radius: 50%;
  color: var(--green-color-dark);
  background-color: var(--gold-color);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 11px;
  width: 30px;
  height: 30px;
}
.wizard_step.active {
  background-color: var(--green-color-dark);
  color: var(--gold-color);
}
.wizard_divider {
  border-bottom: 1px solid rgba(104, 103, 103, 0.4);
  width: 15px;
  height: 16px;
}
.small_font {
  font-size: 11px;
  font-family: "quicksand_bold", Sans-serif;
}
.opacity {
  opacity: 0;
}
.consent {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.form-check {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
}
.bg_overlay {
  background-image: linear-gradient(rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0.7) 10%, rgba(249, 250, 251, 1) 100%);
}
.fullcard .background-green {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media (min-width: 992px) {
  .fullcard .background-green {
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
  }
}
</style>
